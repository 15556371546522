import { IEvent } from 'model/v2/event';

export const FAKE_LOADING_DATA: IEvent = {
  id: 9999999,
  subject: 'FAKE ABA Therapy - Jane Test & Beth Johnson',
  startDate: new Date(),
  duration: 120,
  endDate: new Date(),
  originalStartDate: new Date(),
  originalEndDate: new Date(),
  status: undefined,
  type: undefined,
  note: {
    id: 546870,
    note: 'P1'
  },
  master: {
    id: 1056975
  },
  crStatus: 101,
  athenaStatus: 0,
  isPendingConfirmation: false,
  locationCategory: 'testdata',
  locationType: 'In Clinic',
  clinicPreference: 'allClinics',
  telehealthLink: 'www.telehealthLink.com',
  clinic: {
    id: 8,
    name: 'Plano',
    abbreviation: 'PL'
  },
  client: {
    id: 282,
    clinic: {
      id: 9,
      name: 'San Diego',
      abbreviation: 'SD'
    },
    name: 'Jane Test',
    lastName: 'Test',
    firstName: 'Jane',
    firstNameUsed: 'Jane',
    profile: {
      dob: new Date('2000-01-01'),
      legalSex: 'Female',
      gender: 'Cisgender Female',
      phenoType: 'fakedata',
      phonePreference: 'Mobile',
      consentToCall: 'yes',
      consentToText: 'yes',
      legalGuardianFirstName: 'testFirstName',
      legalGuardianNameLast: 'testLastName',
      personalEmail: 'fakeemail@gmail.com',
      subscriberSSN: '12345678',
      cellPhone: '1232313123'
    }
  },
  provider: {
    id: 575,
    firstName: 'Beth',
    lastName: 'Johnson',
    displayName: 'Beth, Johnson',
    name: 'Beth Johnson',
    mobilePhone: '123456789',
    email: 'bjohnson@corticacare.com',
    telehealth: ['All CA Clinics'],
    isActive: true,
    petAllergies: undefined,
    gender: 'female',
    isObservable: true,
    isTestProvider: false,
    crId: 1342413,
    athenaId: 112,
    msId: '123456789',
    status: 'Active',
    employment: 'Full Time Salary',
    address: undefined,
    speciality: {
      id: 12,
      title: 'Nurse Practitioner',
      abbreviation: 'NP',
      color: '#ffffff',
      department: 'MED'
    },
    appointmentTypes: undefined,
    clinic: {
      id: 8,
      name: 'Plano',
      abbreviation: 'PL',
      timezone: 'America/Chicago',
      address: undefined
    },
    providerCapacity: undefined,
    allowSelfScheduling: true,
    telehealthLinks: ['http://t.com', 'http://test2.com', 'https://test3.com']
  },
  appointmentType: {
    id: 152,
    title: 'ABA Therapy',
    headerColor: '#a3cbe3',
    backgroundColor: '#cae6f6',
    eventType: {
      id: 1,
      name: 'ABA'
    },
    parent: undefined
  },
  recurrencePattern: {
    recurringType: 'weekly',
    recurringEvery: 1,
    recurringUntil: new Date('2026-03-28T23:59:59.000Z')
  },
  eventAudit: [
    {
      id: 1070491,
      action: 'Create',
      actionBy: 'Extension Script',
      actionAt: new Date('2023-09-19T19:39:59.000Z'),
      reason: '',
      note: 'testnote note'
    }
  ],
  address: undefined,
  room: undefined,
  paymentMethod: 'insurance',
  syncAction: undefined,
  observations: [],
  isObservation: false,
  observationsCount: 0,
  driveTimeSourceAddr: undefined,
  driveTimeDesteAddr: undefined,
  driveTimeMileage: undefined,
  isFirstOrLastDriveToNonClinic: undefined,
  reimbursableMileage: undefined
};
