import {
  ICalendarFilterOption,
  ICalendarResource
} from 'model/calendar/filters';
import { internalFilters } from 'utils/constants/calendarFilters';
import { DEBOUNCE_TIME_LIMIT } from 'utils/constants/clientList';

export const applyFilters = (
  providersToFilter: ICalendarResource[],
  locations: ICalendarFilterOption[],
  isPendingFilter: boolean,
  providerFilterMap: Record<string, any> | null,
  searchedProviders: Map<number, ICalendarResource>
) => {
  const locationFilteredProviders = applyLocationFilter(
    providersToFilter,
    locations,
    providerFilterMap,
    searchedProviders
  );

  const pendingFilteredProviders = applyPendingFilter(
    providersToFilter,
    isPendingFilter,
    providerFilterMap,
    searchedProviders
  );
  //get intersection of all applied filters
  const filteredProviders = pendingFilteredProviders.filter(a =>
    locationFilteredProviders.some(b => a.id === b.id)
  );

  return filteredProviders;
};

export const applyLocationFilter = (
  providersToFilter: ICalendarResource[],
  locations: ICalendarFilterOption[],
  providerFilterMap: Record<string, any> | null,
  searchedProviders: Map<number, ICalendarResource>
) => {
  let locationFilteredProviders = providersToFilter;
  if (locations.length > 0) {
    locationFilteredProviders = [];

    for (const p of providersToFilter) {
      // if searched provider skip applying filters
      if (searchedProviders.has(p.id)) {
        locationFilteredProviders.push(p);
        continue;
      }
      for (const f of locations) {
        if (
          providerFilterMap &&
          providerFilterMap[p.id] &&
          providerFilterMap[p.id][f.label]
        ) {
          locationFilteredProviders?.push(p);
          break;
        }
      }
    }
  }
  return locationFilteredProviders;
};
export const waitListFilterHelper = (type: string, FilterData: any) => {
  const CPstatusList = FilterData?.[type]?.reduce((acc: any, obj: any) => {
    if (obj?.title) {
      acc[obj.title] = obj.title;
    }
    if (obj?.name) {
      acc[obj.name] = obj.name;
    }
    return acc;
  }, {});
  return CPstatusList;
};

export const applyPendingFilter = (
  providersToFilter: ICalendarResource[],
  isPendingFilter: boolean,
  providerFilterMap: Record<string, any> | null,
  searchedProviders: Map<number, ICalendarResource>
) => {
  let locPendingFilteredProviders = providersToFilter;
  if (isPendingFilter) {
    locPendingFilteredProviders = [];

    for (const p of providersToFilter) {
      // if searched provider skip applying filters
      if (searchedProviders.has(p.id)) {
        locPendingFilteredProviders.push(p);
        continue;
      }
      if (
        providerFilterMap &&
        providerFilterMap[p.id] &&
        providerFilterMap[p.id][internalFilters.pending]
      ) {
        locPendingFilteredProviders?.push(p);
      }
    }
  }
  return locPendingFilteredProviders;
};

export const getCancelFilterStatus = (internalFilter: internalFilters[]) => {
  return internalFilter?.includes(internalFilters.canceled);
};

export const getDeletedFilterStatus = (internalFilter: internalFilters[]) => {
  return internalFilter?.includes(internalFilters.deleted);
};

export const filterDebounceSearch = (
  data: string,
  debouncedSearch: any,
  searchLimit: number,
  isStaffPage = true
) => {
  data?.length > searchLimit &&
    debouncedSearch({
      variables: {
        name: data?.trim(),
        isClient: false,
        isStaffPage
      }
    });
};

export const debounce = (func: any) => {
  let timeoutId: any;
  return (...args: any) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, DEBOUNCE_TIME_LIMIT);
  };
};
