import { IClinic } from '.';

export interface IClient {
  id: number;
  firstName?: string;
  lastName?: string;
  name?: string;
  usualProvider?: string;
  firstNameUsed?: string;
  availabilityStatus?: string;
  frequency?: string;
  registrationDate?: Date;
  profile?: IClientProfile;
  clinic?: IClinic;
  dayAvailabilities?: ClientDayAvailabilityOutput;
  isActive?: boolean;
}

export interface IClientProfile {
  cellPhone: string;
  consentToCall: string;
  consentToText: string;
  legalGuardianFirstName: string;
  legalGuardianNameLast: string;
  personalEmail: string;
  subscriberSSN: string;
  gender: string;
  dob: Date;
  aboutCortica?: string;
  consentToEmailPHI?: string;
  fatherEmail?: string;
  fatherName?: string;
  motherEmail?: string;
  motherName?: string;
  motherPhone?: string;
  fatherhone?: string;
  legalSex?: string;
  medicalHistoryAuthority?: string;
  memberNumber?: string;
  noticesOnFile?: string;
  patientRelationshipToSubscriber?: string;
  phenoType?: string;
  phonePreference?: string;
  physicianPhoneNumber?: string;
  subscriberFirstName?: string;
  subscriberLastName?: string;
  subscriberDOB?: Date;
  groupNumber?: string;
  homePhone?: string;
  insuranceProvider?: string;
  timezone?: string;
}

export interface ClientDayAvailabilityInput {
  id?: number;
  dayOfWeek: number;
  startTime: string;
  endTime: string;
  locationInput?: number;
}

export interface ClientAvailabilityInput {
  clientId: number;
  availabilities: ClientDayAvailabilityInput[];
}

export interface ClientDayAvailabilityOutput {
  id?: number;
  dayOfWeek: number;
  startTime: string;
  endTime: string;
  location: number;
  customEdit?: boolean;
}

export interface ClientDayAvailabilityTimeSlot {
  id?: number;
  fromLabel?: string;
  from: string;
  toLabel?: string;
  to: string;
}

export interface ClientAvailabilityOutput {
  clientDayAvailabilities?: ClientDayAvailabilityOutput[];
}

export interface ClientClinicNetworkOutput {
  clientClinicNetwork: IClinic[];
}

export enum CLIENT_STATUS_LIST {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export enum CLIENT_EDIT_TYPE {
  languages = 'languages',
  restrictedProviders = 'restrictedProviders',
  preferredProviders = 'preferredProviders',
  allergies = 'petAllergies',
  status = 'status',
  genderPreference = 'genderPreference',
  pets = 'pets',
  id = 'id'
}
