// TODO: remove this file and utilize './default.js'
export const PAGE_SIZE = 10;
export const DEFAULT_SORT_ORDER = [{ name: 'asc' }];
export const DEBOUNCE_TIME_LIMIT = 300;
export const CLIENT_SEARCH_LIMIT = 0;
export const CLIENT_PROVIDER_LIST_MESSAGE = 'Type to search...';
export const CLIENT_RESTRICTED_PROVIDER_HEADER =
  " Mark Provider as 'Do Not Cover'";
export const CLIENT_PREFERRED_PROVIDER_HEADER = " Mark Provider as 'Preferred'";
export const CLIENT_LANGUAGES_HEADER = 'Languages Selected';
export const CLIENT_PETS_HEADER = 'Pets Selected';
