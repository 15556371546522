import { CalendarView, views } from 'utils/cache/calendar';
import { FEATURES } from 'utils/featureToggle';
import { FEATURE_STAGES } from 'utils/featureToggle/features';

export const CLINIC_FILTER = {
  id: 'id',
  value: 'clinicName'
};

export const SPECIALITY_FILTER = {
  id: 'id',
  value: 'abbreviation'
};

export const DEFAULT_FILTER = {
  id: 'id',
  value: 'value'
};

export const SAVED_FILTER = {
  id: 'name',
  value: 'name'
};

export const FILTERED_PROVIDERS_LIMIT = 40;

export const TAG_FILTER = {
  name: 'name',
  isDefault: 'isDefault'
};

export enum DefaultTagFilterOption {
  yes = 'Yes',
  no = 'No'
}
export const IS_DEFAULT_FILTER_TAGS_OPTIONS = [
  {
    value: DefaultTagFilterOption.no,
    label: DefaultTagFilterOption.no
  },
  {
    value: DefaultTagFilterOption.yes,
    label: DefaultTagFilterOption.yes
  }
];
export const DAY_PAGE_SIZES = {
  [FEATURE_STAGES.PROD]: 40,
  [FEATURE_STAGES.STAGING]: 40,
  [FEATURE_STAGES.DEV]: 40
};
export const WEEK_PAGE_SIZES = {
  [FEATURE_STAGES.PROD]: 10,
  [FEATURE_STAGES.STAGING]: 10,
  [FEATURE_STAGES.DEV]: 10
};
export const CALENDAR_MAX_PAGE_SIZE = (view: CalendarView) =>
  view === views.DAY || view === views.PLANNER_DAY
    ? DAY_PAGE_SIZES[FEATURES.CALENDAR_PREVIEW]
    : WEEK_PAGE_SIZES[FEATURES.CALENDAR_PREVIEW];

export const MIN_PER_PAGE = (view: CalendarView) =>
  view === views.DAY || view === views.PLANNER_DAY ? 15 : 3;

export enum CALENDAR_FILTER_ENUM {
  locations = 'locations',
  clinics = 'clinics',
  departments = 'departments',
  specialities = 'specialities',
  internals = 'internals',
  status = 'status',
  programs = 'programs',
  providerCertifications = 'providerCertifications',
  waitListReason = 'waitListReason',
  therapyType = 'therapyType',
  serviceType = 'serviceType',
  clientAvailability = 'clientAvailability',
  dayOfWeeks = 'dayOfWeeks',
  additionalFilter = 'additionalFilter',
  corticaProgram = 'corticaProgram',
  smartSchedulingStatus = 'smartSchedulingStatus',
  language = 'language',
  pets = 'pets',
  genderPreference = 'genderPreference',
  transportation = 'transportation',
  isVBC = 'isVBC',
  insuranceCategories = 'insuranceCategories',
  insurancePayors = 'insurancePayors',
  insurancePlans = 'insurancePlans'
}

export enum internalFilters {
  pending = 'pending',
  canceled = 'canceled',
  deleted = 'deleted'
}
