import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TitleWrapper } from './clients.style';
import Table from 'views/containers/table';
import { CLIENT_COLUMNS } from 'utils/constants/tables/clients';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import {
  GET_CLIENT_Roster_List,
  GET_CLIENT_INITIAL_LOAD_DATA
} from 'api/graphql/v2/queries/Clients';
import {
  WaitListFilterWrapper,
  WaitlistWrapper
} from '../waitlistPage/waitlist.styled';
import PopOverComp from 'components/popover';

import SideBar from 'components/sidebar/sidebar';
import ClientFilterLayout from 'pages/clients/filterLayout';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  mapClientTableInfo,
  updateFinalResults
} from 'utils/mappers/response/clients';
import { table_Skeletal_Loader } from 'utils/mappers/carePlans';
import { Skeleton } from 'antd';
import {
  FilterPageNameEnum,
  savedFilterNameEnum
} from 'components/calendar/calendarToolbar/FilterTagList/form/utils';
import {
  ICalendarFilterData,
  ICalendarFilterOption
} from 'model/calendar/filters';
import FilterTagList from 'components/calendar/calendarToolbar/FilterTagList';
import useCalendarFilterFetcherHook from 'hooks/calendarFilterFetcherHook';
import { CALENDAR_FILTER_ENUM } from 'utils/constants/calendarFilters';
import { CLIENT_EDIT_TYPE, CLIENT_STATUS_LIST } from 'model/v2/clients';
import WaitListSideDrawer from '../sideDrawer/waitListSideDrawer';
import { mapWaitListClientAvailabilityFilters } from 'helpers/clientAvailabilityHelper';
import { ProviderCalendarFiltersAndSearchWrapper } from 'components/calendar/calendarFiltersAndSearch/style';
import ClientSearch from 'pages/provider/input/clientInput';
import { useCloseSelectDropDown } from 'hooks/waitListHook';
import MultiSelectDropDown from 'pages/provider/input/multiSelectDropDown';
import { useReactiveVar } from '@apollo/client';
import { defaultFilterPrefVar } from 'utils/cache/filters';
import { UPDATE_CLIENT_ROSTER } from 'api/graphql/v2/mutations/Client';
import {
  IClientLanguages,
  IClientRosterNotes,
  IClientTable,
  IProviderSelect
} from 'model/client';
import { toast } from 'react-toastify';

interface OptionTypes {
  id?: any;
  displayName: string;
}
export const clientRosterCntx: any = React.createContext(undefined);

const Clients: React.FC<{}> = () => {
  const location = useLocation();

  const [checkedSavedFilter, setCheckedSavedFilter] = useState('');
  const [checkedPrograms, setCheckedPrograms] = useState<
    ICalendarFilterOption[]
  >([]);
  const [tableData, setTableData] = useState<IClientTable[] | []>([]);
  const [hasMore, setHasMore] = useState(true);
  const [calendarFilterData, setCalendarFilterData] = useState<
    ICalendarFilterData
  >();
  const [clientSearch, setClientSearch] = useState<{
    id: undefined | number;
    name: string;
  }>({
    id: undefined,
    name: ''
  });
  const [isShowSideBar, setIsShowDrawer] = useState<Boolean>(false);
  const [checkedFilter, setCheckedFilter] = useState('');
  const [showSidebar, setShowSidebar] = useState(false);
  const [sidebarData, setSidebarData] = useState();

  const [checkedStatus, setCheckedStatus] = useState<ICalendarFilterOption[]>(
    []
  );
  const [onKeysDown, setOnKeyDown] = useState(false);
  const [enteredClients, setEnteredClients] = useState<OptionTypes[]>([]);
  const [isSearchPhase, setIsSearchPhase] = useState(false);
  const [clientAllergies, setClientAllergies] = useState([]);
  const [hasSavedFilters, setHasSavedFilters] = useState(true);
  const [filterLanguages, setFilterLanguages] = useState({});
  const [clickedFilter, setClickedFilter] = useState('');
  const [selectTypeData, setSelectTypeData] = useState([]);
  const [checkedProviderAvailability, setProviderAvailability] = useState<
    ICalendarFilterOption[]
  >([]);
  const [clientList, setClientList] = useState<OptionTypes[]>([]);

  const [checkedLanguage, setCheckedLanguage] = useState<
    ICalendarFilterOption[]
  >([]);
  const [checkedClientAvailability, setClientAvailability] = useState<
    ICalendarFilterOption[]
  >([]);
  const [checkedGenderPreference, setCheckedGenderPreference] = useState<
    ICalendarFilterOption[]
  >([]);
  const [checkedPets, setCheckedPets] = useState<ICalendarFilterOption[]>([]);
  const [additionalFilter, setAdditionalFilter] = useState<any>({
    isFilterApplied: false,
    locations: [],
    startTime: null,
    endTime: null,
    days: []
  });
  const [checkedClinics, setCheckedClinics] = useState<ICalendarFilterOption[]>(
    []
  );

  const [clientLocations, setClientLocations] = useState();
  const [pageNo, setPage] = useState(1);
  let [updateClientMutation] = useMutation(UPDATE_CLIENT_ROSTER);

  const handleChange = (
    additionalUpdateFields: any,
    clientId: number,
    type: string,
    optionType: string,
    selectedOptions?: IProviderSelect
  ) => async (value: IProviderSelect, selectedValues: (string | number)[]) => {
    const mappedFunc = updateFinalResults({
      id: clientId,
      filterLanguages,
      additionalUpdateFields,
      calendarFilterData,
      value,
      selectedValues,
      editType: type,
      optionType,
      selectedOptions
    });
    const mappedTabbleData = mappedFunc.next().value;
    let revertTableRecord: IClientTable;
    let revertTableIndex: number;
    setTableData((prev: IClientTable[]) => {
      let modifiedData = prev.map((data: IClientTable, index: number) => {
        if (data.id === clientId) {
          revertTableIndex = index;
          revertTableRecord = {
            ...data
          };
          if (type === CLIENT_EDIT_TYPE.restrictedProviders) {
            data.providers =
              mappedTabbleData?.length +
              (data.currentProviders ? data?.currentProviders?.length : 0);
          }
          return {
            ...data,
            [type]: mappedTabbleData
          };
        } else {
          return {
            ...data
          };
        }
      });

      modifiedData = modifiedData.filter((filtereddata: any) => {
        // Already existing checks
        const clientLanguages =
          filtereddata?.languages?.map((lang: { name: string }) => lang.name) ||
          [];
        const statusPasses =
          checkedStatusLabel.length === 0 ||
          checkedStatusLabel.includes(filtereddata?.status);
        const languagesPass =
          checkedLanguageLabel.length === 0 ||
          checkedLanguageLabel.every(item => clientLanguages.includes(item));

        // NEW: Pet check (similar logic as languages)
        const clientPets =
          filtereddata?.pets?.map((pet: { name: string }) => pet.name) || [];
        const petPasses =
          checkedPets.map(p => p.label).length === 0 ||
          checkedPets
            .map(p => p.label)
            .every(petName => clientPets.includes(petName));

        const genderPreferencePasses =
          checkedGenderPreference.map(g => g.label).length === 0 ||
          checkedGenderPreference
            .map(g => g.label)
            .includes(filtereddata?.genderPreference);

        return (
          statusPasses && languagesPass && petPasses && genderPreferencePasses
        );
      });
      return modifiedData;
    });

    const mutationUpdatedData = mappedFunc.next().value;
    let data: any = await updateClientMutation({
      variables: { client: mutationUpdatedData }
    });

    if (data) {
      let {
        data: { updateClientRoster }
      } = data;
      if (!updateClientRoster) {
        restorePrevState(revertTableRecord!, revertTableIndex!);
        toast.error(`Error while updating provider ${type}`);
        console.warn('Error while updating record');
      }
    } else {
      restorePrevState(revertTableRecord!, revertTableIndex!);
      toast.error(`Error while updating provider ${type}`);
      console.error('GraphQl Failure');
    }
  };

  const restorePrevState = (recordData: IClientTable, index: number) => {
    setTableData((prev: IClientTable[]) => {
      let previousRecord = [...prev];
      previousRecord[index] = recordData;
      return previousRecord;
    });
  };

  useEffect(() => {
    setCheckedFilter(checkedSavedFilter);
  }, [checkedSavedFilter]);

  const clientFilters = {
    clinics: checkedClinics,
    locations: [],
    programs: checkedPrograms,
    status: checkedStatus,
    language: checkedLanguage,
    clientAvailability: checkedClientAvailability,
    genderPreference: checkedGenderPreference,
    pets: checkedPets,
    savedFilterName: ''
  };

  useEffect(() => {
    setPage(1);
    setTableData([]);
    setHasMore(true);
  }, [
    checkedClinics,
    checkedClientAvailability,
    checkedStatus,
    checkedPrograms,
    additionalFilter.isFilterApplied,
    checkedProviderAvailability,
    checkedLanguage,
    checkedGenderPreference,
    checkedPets,
    clientSearch.id
  ]);

  const {
    defaultFilter,
    savedFilters,
    savedFiltersMap,
    allFilters,
    isSavedFilterLoading,
    useFilterRemovalHook,
    SavedFilter,
    refetch
  } = useCalendarFilterFetcherHook(FilterPageNameEnum.client, 'no-cache');

  const clearAdditionalFilter = React.useCallback(() => {
    setCheckedFilter('');
    setAdditionalFilter({
      isFilterApplied: false,
      locations: [],
      startTime: null,
      endTime: null,
      days: []
    });
  }, [setCheckedFilter, setAdditionalFilter]);

  const handleStaffRemoveFilter = useFilterRemovalHook(
    setCheckedSavedFilter,
    CALENDAR_FILTER_ENUM,
    {
      setFilterOptions: {
        clinics: setCheckedClinics,
        programs: setCheckedPrograms,
        status: setCheckedStatus,
        language: setCheckedLanguage,
        clientAvailability: setClientAvailability,
        genderPreference: setCheckedGenderPreference,
        pets: setCheckedPets
      }
    }
  );

  const [getClientInitalLoadContent, { loading: IsLoading }] = useLazyQuery(
    GET_CLIENT_INITIAL_LOAD_DATA,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data: any) => {
        let {
          petAllergies,
          filterSelections,
          filterList,
          providerLocations,
          genderPreference,
          pets
        } = data.getClientInitialLoadData;
        const allAllergies = petAllergies?.map((item: any) => ({
          value: item.id,
          label: item.value
        }));
        const allGenderPreference = genderPreference?.map((item: any) => ({
          value: item.id,
          label: item.value
        }));
        const allPets = pets?.map((item: any) => ({
          value: item.id,
          label: item.name
        }));
        filterList.corticaProgram = filterList?.corticaProgram?.map(
          (data: any) => {
            return {
              ...data,
              title: data.programName
            };
          }
        );

        if (!defaultFilter)
          setCheckedStatus([
            {
              value: CLIENT_STATUS_LIST.ACTIVE,
              label: CLIENT_STATUS_LIST.ACTIVE
            }
          ]);
        setFilterLanguages(
          filterList?.languages?.reduce(
            (total: Record<string, number>, data: IClientLanguages) => {
              total[data?.name!] = data.id;
              return total; // Make sure to return total after setting the value
            },
            {}
          )
        );
        filterList.languages = filterList?.languages?.map(
          (data: IClientLanguages) => {
            return {
              id: data.id,
              name: data.name
            };
          }
        );
        filterList.genderPreference = allGenderPreference?.map((gp: any) => {
          return {
            title: gp.label
          };
        });
        filterList.pets = allPets?.map((pet: any) => {
          return {
            name: pet.label,
            id: pet.value
          };
        });
        setCalendarFilterData(filterList);
        setClientLocations(providerLocations);
        setClientAllergies(allAllergies);
        setSelectTypeData(filterSelections);
      }
    }
  );
  useEffect(() => {
    getClientInitalLoadContent();
  }, []);

  const loaderView: any = table_Skeletal_Loader(
    Skeleton,
    FilterPageNameEnum.client,
    tableData
  );

  let checkedClinicsIds = checkedClinics.map(clinic => clinic.value);
  let checkedStatusLabel = checkedStatus.map(status => {
    return status.label === CLIENT_STATUS_LIST.ACTIVE ? true : false;
  });
  let checkedAvailability = checkedClientAvailability.map(
    status => status.label
  );
  let checkedLanguageLabel = checkedLanguage.map(language => language.label);

  const {
    data: clientRosterData,
    loading: clientRosterLoading,
    refetch: refetchActualQuery
  } = useQuery(GET_CLIENT_Roster_List, {
    variables: {
      from: pageNo * 25 - 25,
      size: 25,
      search: clientSearch.id ? [clientSearch.id] : undefined,
      clinic: [...checkedClinicsIds],
      status: [...checkedStatusLabel],
      availability: [...checkedAvailability],
      languages: [...checkedLanguageLabel],
      programs: [...checkedPrograms!.map(data => data.label)],
      genderPreference: [...checkedGenderPreference.map(data => data.label)],
      pets: [...checkedPets.map(data => data.label)],
      additionalFilter: mapWaitListClientAvailabilityFilters(
        checkedProviderAvailability,
        additionalFilter,
        { clientLocations: clientLocations }
      )
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const newClients = mapClientTableInfo(
        data?.clientRoster?.clients,
        clientAllergies,
        data?.clientRoster?.clientCurrentProvidersJson
      );
      setTableData(prevPatients => {
        return pageNo > 1 ? [...prevPatients, ...newClients] : newClients;
      });
      if (newClients.length < 25) {
        setHasMore(false);
      }
    }
  });

  const onclientSearchChange = (e: [{ name: string; id: number }]) => {
    let [value] = e;
    if (!value?.name) {
      setClientSearch({ id: undefined, name: '' });
    } else if (value) {
      setClientSearch({
        name: value.name,
        id: value.id
      });
    }
  };
  const useDefaultFilter = useReactiveVar(defaultFilterPrefVar);
  useEffect(() => {
    if (checkedFilter && checkedFilter !== '') handleSavedFilter(checkedFilter);
  }, [savedFilters]);

  useEffect(() => {
    if (defaultFilter && checkedSavedFilter === '') {
      SavedFilter(
        FilterPageNameEnum.client,
        {
          setCheckedClinics,
          setCheckedStatus,
          setCheckedPrograms,
          setClientAvailability,
          setCheckedLanguage
        },
        setAdditionalFilter,
        setCheckedSavedFilter,
        savedFilterNameEnum.default,
        defaultFilter,
        false,
        defaultFilterPrefVar
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter, useDefaultFilter]);

  const handleSavedFilter = useCallback(
    name => {
      const savedFilter = savedFiltersMap.get(name);
      if (!savedFilter) return;
      SavedFilter(
        FilterPageNameEnum.client,
        {
          setCheckedClinics,
          setCheckedStatus,
          setCheckedPrograms,
          setClientAvailability,
          setCheckedLanguage,
          setCheckedGenderPreference,
          setCheckedPets
        },
        setAdditionalFilter,
        setCheckedSavedFilter,
        savedFilterNameEnum.saved,
        savedFilter,
        name
      );
    },
    [savedFiltersMap]
  );

  //Infinity scroll logic to get next set of results
  const fetchMoreData = () => {
    if (clientRosterLoading) {
      return;
    } else {
      setPage(prevPage => prevPage + 1);
    }
  };
  const handleNoteAdded = (clientId: string, addedNote: IClientRosterNotes) => {
    setTableData((prevData: IClientTable[]) => {
      return prevData.map((item: IClientTable) => {
        if (item?.clientId! === clientId) {
          return {
            ...item,
            notes: [...item.notes!, addedNote],
            notesCount: item.notesCount! + 1
          };
        }
        return item;
      });
    });
  };

  const onSetClickedFilter = useCallback(
    label => {
      setClickedFilter(label);
    },
    [setClickedFilter]
  );

  const handleSearchedValues = useCallback(
    list => {
      return list;
    },
    [clientList]
  );

  const clearAllSelectedFilters = useCallback(() => {
    setCheckedFilter('');
    clearAllFilters();
  }, []);
  const clearAllFilters = useCallback(() => {
    setCheckedClinics([]);
    setCheckedPrograms([]);
    setCheckedLanguage([]);
    setCheckedStatus([]);
    setClientAvailability([]);
    setProviderAvailability([]);
    setCheckedGenderPreference([]);
    setCheckedPets([]);
  }, []);

  const filterSelectionType: Record<string, number> = {};
  useMemo(() => {
    selectTypeData?.map((selectionType: { typeName: string; id: number }) => {
      filterSelectionType[selectionType.typeName] = selectionType.id;
    });
  }, [selectTypeData, filterSelectionType]);

  const {
    data: clientRosterDatawithIds,
    loading: clientRosterLoadingwithIds,
    refetch: refetchClientRosterwithIds
  } = useQuery(GET_CLIENT_Roster_List, {
    variables: {
      from: pageNo * 25 - 25,
      size: 25,
      search: enteredClients?.map((id: any) => id.id) || [],
      clinic: [...checkedClinicsIds],
      status: [...checkedStatusLabel],
      availability: [...checkedAvailability],
      languages: [...checkedLanguageLabel],
      programs: [...checkedPrograms.map(data => data.label)],
      genderPreference: [...checkedGenderPreference.map(data => data.label)],
      pets: [...checkedPets.map(data => data.label)],
      additionalFilter: mapWaitListClientAvailabilityFilters(
        checkedProviderAvailability,
        additionalFilter,
        { clientLocations: clientLocations }
      )
    },
    skip: !enteredClients?.length || !onKeysDown, // Skip the query if enteredClients is empty or onKeysDown is false
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: async data => {
      try {
        if (data?.clientRoster?.clients) {
          // Map the client roster data
          const newClients = mapClientTableInfo(
            data.clientRoster.clients,
            clientAllergies,
            data.clientRoster.clientCurrentProvidersJson
          );
          // Update the table data state
          setTableData(newClients);
          setOnKeyDown(false);
          // Check if there's more data to load
          if (newClients.length < 25) {
            setHasMore(false);
          }
        }
      } catch (error) {
        console.error('Error in onCompleted callback:', error);
      }
    },
    onError: error => {
      console.error('Error while fetching client roster:', error);
    }
  });

  // Effect to reset page and table data when enteredClients or onKeysDown changes
  useEffect(() => {
    if (enteredClients?.length > 0 && onKeysDown) {
      setIsSearchPhase(true);
      setPage(1);
      setTableData([]);
      refetchClientRosterwithIds();
    }
  }, [enteredClients, onKeysDown, refetchClientRosterwithIds]);

  useEffect(() => {
    if (clientSearch.name === '') {
      setIsSearchPhase(false);
      setHasMore(true);
    }
  }, [clientSearch.name]);

  useEffect(() => {
    if (!isSearchPhase) {
      setTableData([]);
      refetchActualQuery();
    }
  }, [isSearchPhase, refetchActualQuery]);

  useCloseSelectDropDown('.table-scroll-container');

  return (
    <div>
      <TitleWrapper>
        <div className="inner-wrap client-roster-head">
          <h1>Client Roster</h1>
        </div>
      </TitleWrapper>
      <clientRosterCntx.Provider value={setCheckedSavedFilter}>
        <WaitListFilterWrapper isStaff={true}>
          <div className="actions">
            <div className="dropdown-wrapper provider-dropdown-wrapper"> </div>
            <ClientFilterLayout
              isLoading={IsLoading}
              FilterData={calendarFilterData!}
              checkedClinics={checkedClinics}
              setCheckedClinics={setCheckedClinics}
              checkedPrograms={checkedPrograms}
              setCheckedPrograms={setCheckedPrograms}
              checkedStatus={checkedStatus}
              setCheckedStatus={setCheckedStatus}
              setIsShowDrawer={setIsShowDrawer}
              setCheckedLanguage={setCheckedLanguage}
              checkedLanguage={checkedLanguage}
              setClientAvailability={setClientAvailability}
              checkedClientAvailability={checkedClientAvailability}
              setCheckedGenderPreference={setCheckedGenderPreference}
              checkedGenderPreference={checkedGenderPreference}
              setCheckedPets={setCheckedPets}
              checkedPets={checkedPets}
            >
              {() => {
                let ClientSearchWrapper = (
                  <ProviderCalendarFiltersAndSearchWrapper isStaffPage={true}>
                    <ClientSearch
                      clientSearch={clientSearch.name}
                      onclientSearchChange={onclientSearchChange}
                      setClientList={setClientList}
                      clientList={clientList}
                      setEnteredClients={setEnteredClients}
                      setOnKeyDown={setOnKeyDown}
                    />
                  </ProviderCalendarFiltersAndSearchWrapper>
                );
                return { ClientSearchWrapper };
              }}
            </ClientFilterLayout>
          </div>

          <WaitlistWrapper isStaff={true}>
            <div className="inner-wrap staff-filters">
              <FilterTagList
                filters={clientFilters}
                refetch={refetch}
                onRemoveFilter={handleStaffRemoveFilter}
                showSavedIcon={true}
                additionalFilter={additionalFilter}
                clearAdditionalFilter={clearAdditionalFilter}
                checkedFilter={checkedFilter}
                setCheckedSavedFilter={setCheckedSavedFilter}
                pageName={FilterPageNameEnum.client}
                clearAllSelectedFilters={clearAllSelectedFilters}
                showClearFilters={true}
              />
            </div>
            <div className="inner-wrap provider-wrap client-wrap">
              <InfiniteScroll
                dataLength={tableData.length}
                next={fetchMoreData}
                hasMore={hasMore}
                className={`infinite-scroll-component ${
                  tableData?.length === 1 ? 'ind-table-scroll' : ''
                }`}
                endMessage={
                  <p style={{ textAlign: 'center' }}>No more clients</p>
                }
                loader={''}
              >
                <Table
                  className="custom-table-class"
                  columns={CLIENT_COLUMNS(
                    clientRosterLoading,
                    handleChange,
                    setShowSidebar,
                    setSidebarData,
                    clientAllergies,
                    calendarFilterData,
                    MultiSelectDropDown,
                    PopOverComp
                  )}
                  isClient={true}
                  data={
                    clientRosterLoading || clientRosterLoadingwithIds
                      ? loaderView
                      : tableData
                  }
                  total={
                    clientRosterLoading || clientRosterLoadingwithIds
                      ? 6
                      : clientRosterData?.clientRoster?.count
                      ? clientRosterData?.clientRoster?.count
                      : clientRosterDatawithIds
                      ? clientRosterDatawithIds
                      : 6
                  }
                  clearAllSelectedFilters={clearAllSelectedFilters}
                  page={pageNo}
                  pagination={false}
                  onPageChangeCallback={() => {}}
                  onRowSelect={() => {}}
                  rowClassName={() => {
                    return 'custom-provider-row custom-client-row';
                  }}
                />
              </InfiniteScroll>
              {showSidebar ? (
                <SideBar
                  show={showSidebar}
                  close={() => setShowSidebar(!setShowSidebar)}
                  // setShowSidebar={setShowSidebar}
                  sidebarData={sidebarData}
                  setSidebarData={setSidebarData}
                  onNoteAdded={handleNoteAdded}
                  pageName={FilterPageNameEnum.client}
                  activeTab={'notes'}
                />
              ) : (
                <></>
              )}
            </div>
            {isShowSideBar && (
              <WaitListSideDrawer
                refetch={refetch}
                isShowDrawer={isShowSideBar}
                setAdditionalFilter={setAdditionalFilter}
                waitListDataLoading={IsLoading}
                Skeleton={Skeleton}
                additionalFilter={additionalFilter}
                locations={clientLocations}
                location={location}
                setIsShowDrawer={setIsShowDrawer}
                selectSavedFilter={handleSavedFilter}
                setCheckedFilter={setCheckedFilter}
                savedFilter={checkedSavedFilter}
                handleSearchedValues={handleSearchedValues}
                setHasData={setHasSavedFilters}
                clickedFilter={clickedFilter}
                hasSavedFilters={hasSavedFilters}
                setClickedFilter={onSetClickedFilter}
                savedFilters={savedFilters}
                defaultFilter={defaultFilter}
                isSavedFilterLoading={isSavedFilterLoading && IsLoading}
                pageName={FilterPageNameEnum.client}
                checkedFilter={checkedFilter}
                clearAllFilters={clearAllFilters}
                filterSelectionType={filterSelectionType}
                allFilters={allFilters}
              />
            )}
          </WaitlistWrapper>
        </WaitListFilterWrapper>
      </clientRosterCntx.Provider>
    </div>
  );
};

export default Clients;
