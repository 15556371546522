import { IAppointmentType, AppointmentType } from 'model/appointment';
import {
  ABAAppointmentSubType,
  IABAAppointmentSubType
} from 'model/abaAppointmentSubtype';
import {
  IDTAppointmentSubType,
  DTAppointmentSubType
} from 'model/DTAppointmentSubType';
import {
  IMEDAppointmentSubType,
  MEDAppointmentSubType
} from 'model/MEDAppointmentSubtype';
import { Lookup } from 'model/lookup';
import { IProviderSpecialty, Specialty } from 'model/v2';
import {
  IAvailabilityTemplate,
  AvailabilityTemplate
} from 'model/availabilityTemplate';
import { IRoom } from 'model/v2';

export const NEW_CLIENT = {
  // provider/registration
  usualProvider: { id: undefined },
  clinic: { id: undefined },
  registrationDate: undefined,
  // identification
  firstName: undefined,
  lastName: undefined,
  firstNameUsed: undefined,
  clientTypeNote: { id: undefined, note: '' },
  clientType: undefined,
  profile: {
    dob: undefined,
    legalSex: undefined,
    gender: undefined,
    cellPhone: undefined,
    homePhone: undefined,
    phonePreference: undefined,
    consentToText: undefined,
    personalEmail: undefined,
    // additional
    motherName: undefined,
    motherPhone: undefined,
    motherEmail: undefined,
    motherNote: { id: undefined, note: '' },
    fatherNote: { id: undefined, note: '' },
    note: { id: undefined, note: '' },
    fatherName: undefined,
    fatherPhone: undefined,
    fatherEmail: undefined,
    otherAuthorizedGuardians: undefined,
    consentToEmailPHI: undefined,
    aboutCortica: undefined,
    // privacy
    noticesOnFile: false,
    consentToCall: undefined,
    medicalHistoryAuthority: undefined,
    // insurance
    insuranceProvider: undefined,
    memberNumber: undefined,
    groupNumber: undefined,
    subscriberFirstName: undefined,
    subscriberLastName: undefined,
    subscriberDOB: undefined,
    subscriberSSN: undefined,
    patientRelationshipToSubscriber: undefined,
    physicianPhoneNumber: undefined,
    // legal guardian
    legalGuardianFirstName: undefined,
    legalGuardianLastName: undefined,
    // medical profile
    phenoType: undefined,
    behavior: undefined,
    cognition: undefined,
    communication: undefined,
    sensorimotor: undefined
  },
  addresses: [
    {
      addressLine1: undefined,
      addressLine2: undefined,
      city: undefined,
      state: undefined,
      zipCode: undefined,
      name: undefined
    }
  ],
  pets: [],
  genderPreference: 'No Preference'
};

export const NEW_AUTH_THERAPY = {
  apptTypes: [undefined],
  authLimit: undefined,
  authPattern: undefined,
  authPeriod: undefined
};

export const NEW_AUTH_GROUP = {
  authGroupID: undefined,
  authGroupType: undefined,
  clientID: undefined,
  clientName: undefined,
  clinicAbr: undefined,
  clinicID: undefined,
  startDate: undefined,
  endDate: undefined,
  dueDate: undefined,
  authTherapies: [NEW_AUTH_THERAPY]
};

// TODO: fix variables type
export const NEW_RECOMMENDED_EVALUATION = (
  clientResponse: any,
  apptType: any
) => ({
  apptType,
  apptSubType: undefined,
  clientResponse
});

export const NEW_RECOMMENDED_THERAPY = {
  apptType: undefined,
  apptSubType: undefined,
  recommendedQuantity: undefined,
  requestedQuantity: undefined,
  quantityPattern: undefined
};

export const NEW_CARE_PLAN = {
  recommendedEvaluations: [],
  recommendedTherapies: []
};

export const NEW_APPOINTMENT_CONFIG: IAppointmentType = {
  [AppointmentType.id]: 0,
  [AppointmentType.title]: '',
  [AppointmentType.headerColor]: '',
  [AppointmentType.backgroundColor]: '',
  [AppointmentType.isEvaluation]: false,
  [AppointmentType.ActiveStatus]: true,
  [AppointmentType.dayAvailabilityTypes]: [],
  [AppointmentType.paycodes]: ''
};

//TODO: Adjust initial values when form is added
export const NEW_ABA_APPOINTMENT_SUBTYPE_CONFIG: IABAAppointmentSubType = {
  [ABAAppointmentSubType.id]: 0,
  [ABAAppointmentSubType.title]: '',
  [ABAAppointmentSubType.crAuthCode]: {
    [Lookup.id]: '',
    [Lookup.value]: ''
  },
  [ABAAppointmentSubType.crType]: '',
  [ABAAppointmentSubType.placeOfService]: {
    [Lookup.id]: '',
    [Lookup.value]: ''
  },
  [ABAAppointmentSubType.superType]: {
    [Lookup.id]: '',
    [Lookup.value]: ''
  }
};

export const NEW_DT_APPOINTMENT_SUBTYPE_CONFIG: IDTAppointmentSubType = {
  [DTAppointmentSubType.id]: 0,
  [DTAppointmentSubType.title]: '',
  [DTAppointmentSubType.athenaId]: '',
  [DTAppointmentSubType.crName]: '',
  [DTAppointmentSubType.crType]: '',
  [DTAppointmentSubType.duration]: 0,
  [DTAppointmentSubType.superType]: {
    [Lookup.id]: '',
    [Lookup.value]: ''
  }
};

export const NEW_MED_APPOINTMENT_SUBTYPE_CONFIG: IMEDAppointmentSubType = {
  [MEDAppointmentSubType.id]: 0,
  [MEDAppointmentSubType.title]: '',
  [MEDAppointmentSubType.athenaId]: '',
  [MEDAppointmentSubType.crName]: '',
  [MEDAppointmentSubType.crType]: '',
  [MEDAppointmentSubType.duration]: 0,
  [MEDAppointmentSubType.superType]: {
    [Lookup.id]: '',
    [Lookup.value]: ''
  }
};
export const NEW_SPECIALITIES_CONFIG: IProviderSpecialty = {
  [Specialty.id]: 0,
  [Specialty.title]: '',
  [Specialty.color]: '',
  [Specialty.abbreviation]: '',
  [Specialty.department]: ''
};

export const NEW_AVAILABILITY_TEMPLATE_CONFIG: IAvailabilityTemplate = {
  [AvailabilityTemplate.id]: '',
  [AvailabilityTemplate.name]: '',
  [AvailabilityTemplate.title]: '',
  [AvailabilityTemplate.department]: '',
  [AvailabilityTemplate.speciality]: { abbreviation: '' },
  [AvailabilityTemplate.createdAt]: '',
  [AvailabilityTemplate.createdBy]: '',
  [AvailabilityTemplate.isActive]: true,
  [AvailabilityTemplate.default]: false
};

export const NEW_ROOM = (clinicId?: number): IRoom => ({
  clinic: {
    id: clinicId
  },
  hasInstruments: false,
  hasTV: false,
  name: '',
  type: '',
  seats: undefined
});
