import { convertToDate } from 'utils/format';
import { YES_NO_LIST } from 'utils/constants/lists';
import { cloneWithoutTypename } from 'utils/common';
export const getClientFormData = client => {
  let editedClient = {
    ...client,
    pets: client?.pets?.map(data => {
      return data?.value ? { id: data?.value } : undefined;
    }),
    genderPreference: client?.genderPreference,
    clinic: {
      id: client.clinic.id
    },
    corticaPrograms: client?.corticaPrograms.map(data => {
      return { id: data };
    }),
    usualProvider: {
      id: client.usualProvider.id
    },
    registrationDate:
      client.registrationDate && client.registrationDate.isValid()
        ? convertToDate(client.registrationDate)
        : null,
    petAllergies: client?.petAllergies?.reduce((sum, nextData) => {
      return sum + nextData;
    }, 0),
    restrictedProviders: client?.restrictedProviders?.map(data => {
      return {
        id: data.id,
        name: data.name
      };
    }),
    preferredProviders: client?.preferredProviders?.map(data => {
      return {
        id: data.id,
        name: data.name
      };
    }),
    profile: {
      ...client.profile,
      dob: convertToDate(client?.profile?.dob),
      subscriberDOB:
        client.profile?.subscriberDOB && client.profile?.subscriberDOB.isValid()
          ? convertToDate(client.profile.subscriberDOB)
          : null,
      noticesOnFile: YES_NO_LIST[1 - +client.profile?.noticesOnFile].value
    }
  };
  editedClient = cloneWithoutTypename(editedClient);

  return editedClient;
};
