import { Select, Checkbox } from 'antd';
import { OptionType } from 'antd/lib/select';
import { GET_ATTENDEES } from 'api/graphql/v2/queries/Attendees';
import { debounce, filterDebounceSearch } from 'helpers/filterHekper';
import React, { useRef } from 'react';
import { useLazyQuery } from 'react-apollo';
import { alphabeticalSort } from 'utils/sort';
import Loader from '../../../views/components/ui/content/Loader';
import { SelectDropDownWrapper } from 'views/components/waitlistPage/waitlist.styled';
import tagRender from 'lib/ui/tag/tagRender';
import { IProviderSelect } from 'model';
import { CLIENT_EDIT_TYPE } from 'model/v2/clients';

interface IProps {
  className: string;
  selectOptions?: IProviderSelect[];
  placeholder: React.ReactNode;
  header: React.ReactNode;
  selectedOptions: IProviderSelect[];
  overAllOptions: IProviderSelect[];
  handleChange: (
    data: IProviderSelect,
    selectedItems: (string | number)[]
  ) => void;
  selectType: string;
  selectId: string;
  optionMapType: keyof IProviderSelect;
  displayContent: keyof IProviderSelect;
  maxTagCount?: number;
  dropdownClassName?: string;
  optionLabelProp?: string;
  filterOption?: any;
}

const MultiSelectDropDown = ({
  header,
  selectedOptions,
  handleChange,
  selectId,
  selectType,
  overAllOptions,
  optionMapType,
  displayContent,
  selectOptions,
  ...restProps
}: IProps) => {
  const { Option } = Select;
  const [options, setOptions] = React.useState<IProviderSelect[]>(
    overAllOptions
  );
  let selectedItems: (string | number)[] = selectedOptions?.map(
    (data: IProviderSelect) => data[optionMapType]!
  );

  const [
    doClientRequest,
    { data: clientLists, loading: clientLoading }
  ] = useLazyQuery(GET_ATTENDEES, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const nonDisplayedAttendees = clientLists?.attendees?.sort(
        (a1: OptionType, a2: OptionType) =>
          alphabeticalSort(a1.displayName, a2.displayName)
      );
      setOptions(nonDisplayedAttendees);
    }
  });
  const debouncedSearch = useRef(debounce(doClientRequest)).current;

  const handleSearch = (searchTerm: string) => {
    if (
      selectType === CLIENT_EDIT_TYPE.restrictedProviders ||
      selectType === CLIENT_EDIT_TYPE.preferredProviders
    ) {
      if (searchTerm && searchTerm?.length > 3) {
        filterDebounceSearch(searchTerm, debouncedSearch, 3, false);
      } else {
        setOptions([]);
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (restProps.dropdownClassName === 'custom-dropdown-select') {
        const dropdownList = document.querySelector(
          `.ant-select-dropdown.${restProps.dropdownClassName || ''}`
        );
        if (dropdownList) {
          const activeItem = dropdownList.querySelector(
            '.ant-select-item-option-active'
          );
          if (activeItem) {
            const capturedId = activeItem.getAttribute('data-value');
            if (capturedId) {
              const selectedOption = options.find(
                option => option[optionMapType]?.toString() === capturedId
              );
              if (selectedOption) {
                handleChange(selectedOption, [
                  ...selectedItems,
                  selectedOption.id
                ]);
              } else {
                console.warn('Selected option not found in the options list');
              }
            } else {
              console.warn('Captured ID is null or invalid');
            }
          }
        }
      } else {
        const dropdownList = document.querySelector(
          `.ant-select-dropdown.${restProps.dropdownClassName || ''}`
        );
        if (dropdownList) {
          const activeItem = dropdownList.querySelector(
            '.ant-select-item-option-active'
          );
          if (activeItem) {
            const capturedId = activeItem.getAttribute('data-value');
            if (capturedId) {
              const selectedOption = options.find(
                option => option[optionMapType]?.toString() === capturedId
              );
              if (selectedOption) {
                handleChange(selectedOption, selectedItems);
              } else {
                console.warn('Selected option not found in the options list');
              }
            } else {
              console.warn('Captured ID is null or invalid');
            }
          }
        }
      }
    }
  };

  return (
    <Select
      {...restProps}
      mode="multiple"
      // open={true}
      showSearch
      tagRender={tagRender}
      onDropdownVisibleChange={open => {
        if (!open) {
          setOptions([]);
        } else {
          setOptions(overAllOptions);
        }
      }}
      value={selectedItems}
      onInputKeyDown={handleKeyPress}
      onSearch={handleSearch} // Trigger search logic on input
      maxTagPlaceholder={<span>+{selectedItems.length - 1} more</span>} // Show number of additional selected items
      dropdownRender={menu => (
        <SelectDropDownWrapper>
          {clientLoading &&
          selectType === CLIENT_EDIT_TYPE.restrictedProviders ? (
            <div style={{ textAlign: 'center', padding: '10px' }}>
              <Loader />
            </div>
          ) : (
            <>
              {header}
              {menu}
            </>
          )}
        </SelectDropDownWrapper>
      )}
    >
      {selectedOptions?.map((data: IProviderSelect, index: number) => (
        <Option
          className={`provider-select-options selected-languages-outer-wrapper ${index ===
            selectedOptions?.length - 1 && 'selected-last-index'}`}
          key={index}
          value={data[optionMapType]!}
          label={data[displayContent]}
        >
          <div key={index}>
            <Checkbox
              onChange={() => {
                handleChange(data, selectedItems);
              }}
              checked={selectedItems.includes(data[optionMapType]!)}
            >
              {data[displayContent]}
            </Checkbox>
          </div>
        </Option>
      ))}
      {options?.map((data: IProviderSelect, index: number) => (
        <Option
          key={data[displayContent]}
          value={data[optionMapType]!}
          data-value={data[optionMapType]!}
          label={data[displayContent]}
          className={`provider-select-options ${index === 0 &&
            'selected-first-index'}`}
        >
          <Checkbox
            onChange={() => {
              handleChange(data, selectedItems);
            }}
            checked={selectedItems.includes(data[optionMapType]!)}
          >
            {data[displayContent]}
          </Checkbox>
        </Option>
      ))}
    </Select>
  );
};

export default MultiSelectDropDown;
