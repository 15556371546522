import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import Content from 'views/components/ui/content';
import Header from 'views/containers/header';
import { AddFormWrapper } from 'views/components/clients/clients.style';
import { CLIENT_FORM_SCHEMA } from 'utils/validators/clients';
import { mapToOption } from 'utils/mappers/form';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { GET_CLIENT_FORM_DATA } from 'api/graphql/v2/queries/Clients';
import { UPDATE_CLIENT, ADD_CLIENT } from 'api/graphql/v2/mutations/Client';
import { getClientFormData } from 'utils/mappers/request/clients';
import { ClientProfileForm } from './ClientProfileForm';
import { ClientLegalGuardianForm } from './ClientLegalGuardianForm';
import { ClientInsuranceForm } from './ClientInsuranceForm';
import { ClientPrivacyForm } from './ClientPrivacyForm';
import { ClientAdditionalInfoForm } from './ClientAdditionalInfoForm';
import { ClientProviderForm } from './ClientProviderForm';
import { ClientIdentificationForm } from './ClientIdentificationForm';
import { ClientContactForm } from './ClientContactForm';
import { formatClientAddress, formatUsernameOriginal } from 'utils/format';
import { mapCorticaProgramOptions } from 'utils/mappers/form';
import { GET_CLIENT_INITIAL_LOAD_DATA } from 'api/graphql/v2/queries/Clients';
export const ClientForm = ({ client, isCreate, disabledFormFields }) => {
  const [providerOptions, setProviderOptions] = useState([]);
  const history = useHistory();
  const [providerAllergies, setProviderAllergies] = useState([]);
  const [petOptions, setPetOptions] = useState([]);
  const [genderPrefOptions, setGenderPrefOptions] = useState([]);

  const updateClinicProviderOptions = useCallback((clinicId, clinics) => {
    const selectedClinicObject = clinics?.find(c => c.id === clinicId);
    if (selectedClinicObject)
      setProviderOptions(
        selectedClinicObject.providers
          ? mapToOption(
              selectedClinicObject.providers.map(provider => ({
                id: provider.id,
                value: formatUsernameOriginal(
                  //TODO : check computed value
                  provider?.firstName,
                  provider?.lastName
                )
              }))
            )
          : []
      );
  }, []);

  const { data, loading, error } = useQuery(GET_CLIENT_FORM_DATA, {
    onCompleted: data => {
      if (client.clinic?.id)
        updateClinicProviderOptions(
          client.clinic?.id,
          data?.clinicsListWithProviders
        );
    }
  });

  const [updateClient] = useMutation(UPDATE_CLIENT, {
    onCompleted: () => {
      history.goBack();
    }
  });

  const [createClient] = useMutation(ADD_CLIENT, {
    onCompleted: data => {
      history.push('/clients');
    }
  });

  const onSubmit = useCallback(
    (values, actions) => {
      actions.setSubmitting(true);
      formatClientAddress(values.addresses);
      if (!isCreate) {
        return updateClient({
          variables: { client: getClientFormData(values) }
        }).then(_ => actions.setSubmitting(false));
      } else {
        createClient({
          variables: { client: getClientFormData(values) }
        }).then(() => actions.setSubmitting(false));
      }
    },
    [isCreate, updateClient, createClient]
  );

  const [
    getClientInitalLoadContent,
    { loading: IsLoading, data: initialClientContent }
  ] = useLazyQuery(GET_CLIENT_INITIAL_LOAD_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      let {
        petAllergies,
        filterSelections,
        filterList,
        providerLocations,
        genderPreference,
        pets
      } = data.getClientInitialLoadData;
      const allAllergies = petAllergies?.map(item => ({
        value: item.id,
        label: item.value
      }));
      setProviderAllergies(allAllergies);
      const allPets = pets?.map(item => ({
        value: item.id,
        name: item.name
      }));
      setPetOptions(allPets);
      const allGenderPref = genderPreference?.map(item => ({
        value: item.value,
        label: item.value
      }));
      setGenderPrefOptions(allGenderPref);
    },
    onError: error => {
      console.error(`Error while fetching client option list ${error}`);
    }
  });
  const {
    corticaProgram: corticaPrograms,
    languages,
    pets,
    genderPreference
  } = useMemo(() => {
    if (initialClientContent) {
      let {
        getClientInitialLoadData: {
          filterList: { corticaProgram, languages }
        },
        pets,
        genderPreference
      } = initialClientContent;
      return {
        corticaProgram: mapCorticaProgramOptions({
          corticaPrograms: corticaProgram
        }),
        languages: languages,
        pets: pets,
        genderPreference: genderPreference
      };
    } else
      return {
        corticaProgram: [],
        languages: [],
        pets: [],
        genderPreference: 'No Preference'
      };
  }, [initialClientContent]);

  useEffect(() => {
    getClientInitalLoadContent();
  }, [getClientInitalLoadContent]);

  const onCancel = useCallback(() => history.goBack(), [history]);

  return (
    <Content data={data} loading={loading} error={error}>
      {() => (
        <AddFormWrapper className="popup-wrap">
          <Formik
            initialValues={client}
            validationSchema={CLIENT_FORM_SCHEMA}
            onSubmit={(values, actions) => onSubmit(values, actions)}
            render={({
              handleSubmit,
              isSubmitting,
              dirty,
              values,
              setFieldValue,
              getFieldValue
            }) => (
              <>
                <Header
                  title="Edit Client"
                  handleSubmit={handleSubmit}
                  onCancel={onCancel}
                  buttonText="Save Client"
                  loading={isSubmitting}
                  disabled={!dirty}
                />
                <div className="inner-wrap">
                  <ClientProviderForm
                    clinicsWithoutRooms={data?.clinicsListWithProviders || []}
                    updateClinicProviderOptions={updateClinicProviderOptions}
                    providerOptions={providerOptions}
                    clientTypes={data?.clientTypes || []}
                    disabledFormFields={disabledFormFields}
                  />
                  <ClientIdentificationForm
                    disabledFormFields={disabledFormFields}
                  />
                  <ClientContactForm
                    data={values}
                    setFieldValue={setFieldValue}
                    disabledFormFields={disabledFormFields}
                  />
                  <ClientAdditionalInfoForm
                    disabledFormFields={disabledFormFields}
                  />
                  <ClientPrivacyForm disabledFormFields={disabledFormFields} />
                  <ClientInsuranceForm
                    patientRelationships={data?.patientRelationships || []}
                    disabledFormFields={disabledFormFields}
                  />
                  <ClientLegalGuardianForm
                    disabledFormFields={disabledFormFields}
                  />
                  <ClientProfileForm
                    values={values}
                    languages={languages}
                    setFieldValue={setFieldValue}
                    providerAllergies={providerAllergies}
                    disabledFormFields={disabledFormFields}
                    corticaPrograms={corticaPrograms}
                    petOptions={petOptions}
                    genderPrefOptions={genderPrefOptions}
                  />
                </div>
              </>
            )}
          />
        </AddFormWrapper>
      )}
    </Content>
  );
};
